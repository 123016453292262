import { Model } from 'modapp-resource';
import l10n from 'utils/l10n';

const mainRoles = [
	{ id: 'staff', name: l10n.l('auth.admin', "Administratör") },
	{ id: 'educator', name: l10n.l('auth.educator', "Utbildare") },
	{ id: 'participant', name: l10n.l('auth.participant', "Deltagare") },
];

/**
 * Auth fetches the currently logged in user on start, or redirects to the login
 * page if the user is not logged in.
 */
class Auth {

	constructor(app, params) {
		this.app = app;
		this.params = Object.assign({
			loginUrl: '/auth',
			logoutUrl: '/auth/logout',
			authenticateUrl: '/auth?noredirect',
			redirected: false
		}, params);

		// Bind callbacks
		this._onConnect = this._onConnect.bind(this);
		this._onUnsubscribe = this._onUnsubscribe.bind(this);

		this.app.require([ 'api' ], this._init.bind(this));
	}

	/**
	 * Gets the auth model containing the currently logged in user.
	 * @returns {Model} The auth model.
	 */
	getModel() {
		return this.model;
	}

	/**
	 * Gets the auth roles model containing all the roles of the logged in user.
	 * @returns {Model} The roles model.
	 */
	getRoles() {
		return this.model.roles;
	}

	/**
	 * Gets the model of the logged in user.
	 * @returns {Model} The user model.
	 */
	getUser() {
		return this.model.user || null;
	}

	/**
	 * Gets a promise to the logged in user.
	 * It resolves as soon as the user is logged in and fetched.
	 * @returns {Promise.<Model>} Currently logged in user.
	 */
	getUserPromise() {
		return this.extUserPromise;
	}

	/**
	 * Gets a list of the main roles.
	 * @returns {Array.<object>} Array of role objects: { id, name }
	 */
	getMainRoles() {
		return mainRoles;
	}

	/**
	 * Sets the active role of the user. The role must be a main role held by the user.
	 * @param {string} roleId ID of role.
	 */
	setActiveRole(roleId) {
		for (let r of mainRoles) {
			if (roleId == r.id) {
				if (!this.model.roles[roleId]) {
					console.error("User does not have role: ", roleId);
				} else {
					this.model.set({ activeRole: roleId });
					if (sessionStorage && this.model.user) {
						sessionStorage.setItem('auth.user.' + this.model.user.id + '.activeRole', roleId);
					}
				}
				return;
			}
		}
		throw new Error("Invalid main role: " + roleId);
	}

	/**
	 * Redirects the browser to the login page.
	 */
	redirectToLogin() {
		if (this.params.redirected) {
			console.log("Already redirected");
			return;
		}
		window.location.replace(this.params.loginUrl + '?redirect_uri=' + encodeURIComponent(window.location.href));
	}

	/**
	 * Authenticates the user or redirects to login if not logged in.
	 *
	 * The returned promise will be rejected if the API returns any other error
	 * than 'user.authenticationFailed'. If user.authenticationFailed is
	 * returned, the auth module will redirect the client to the authentication
	 * endpoint.
	 * @param {boolean} [noRedirect] Flag that prevents failed authentication to redirect to login.
	 * @returns {Promise} Promise to the authenticate.
	 */
	authenticate(noRedirect) {
		return fetch(this.params.authenticateUrl, {
			method: 'POST',
			mode: 'cors',
			credentials: 'include',
		}).catch(err => {
			// Rethrow fetch errors as a more friendly message.
			throw { code: 'auth.authFetchFailed', message: "Det gick inte att nå Nercia API. Kontakta Nercia om felet kvarstår.", data: err };
		}).then(resp => {
			if (resp.status >= 400) {
				return resp.json().then(err => {
					if (!noRedirect && resp.status < 500) {
						this.redirectToLogin();
						return;
					}
					throw err;
				});
			}
			return this._getCurrentUser().catch(err => {
				// On access denied, we have failed to authentication and
				// shouldredirect.
				if (!noRedirect && (err.code == 'system.accessDenied' || err.code == 'auth.invalidToken')) {
					this.redirectToLogin();
					return;
				}
				// Rewrite the system.connectionError to a friendly message.
				if (err.code == 'system.connectionError') {
					err = { code: err.code, message: "Det gick inte att ansluta till Nercia API. Kontakta Nercia om felet kvarstår." };
				}
				// Or else we throw the error to be handled by the caller to show an
				// error message.
				throw err;
			});
		});
	}

	/**
	 * Redirects the browser to the login page.
	 */
	logout() {
		window.location.replace(this.params.logoutUrl + '?redirect_uri=' + encodeURIComponent(window.location.href));
	}

	/**
	 * Check if the current user has all of the provided roles.
	 * @param {string|Array.<string>} roles Role(s).
	 * @returns {boolean} True if the user has all roles, otherwise false.
	 */
	hasRoles(roles) {
		if (!roles) {
			return true;
		}
		roles = Array.isArray(roles) ? roles : arguments;
		let rs = this.getRoles();
		for (let r of roles) {
			if (!rs[r]) {
				return false;
			}
		}
		return true;
	}

	getRoleName(role) {
		for (let r of mainRoles) {
			if (r.id == role) {
				return r.name;
			}
		}
		return l10n.l('auth.missingRole', "Saknar roll");
	}

	/**
	 * Check if the current user has any of the provided roles.
	 * @param {string|Array.<string>} roles Role(s).
	 * @returns {boolean} True if the user has any of the roles, otherwise false.
	 */
	hasAnyRole(roles) {
		if (!roles) {
			return false;
		}
		roles = Array.isArray(roles) ? roles : arguments;
		let rs = this.getRoles();
		for (let r of roles) {
			if (rs[r]) {
				return true;
			}
		}
		return false;
	}

	_init(module) {
		this.module = module;
		this.userPromise = null;
		this._createExtUserPromise();
		this.model = new Model({
			data: {
				loggedIn: false,
				user: null,
				roles: new Model({
					eventBus: this.app.eventBus
				}),
				activeRole: null
			},
			eventBus: this.app.eventBus
		});

		this.module.api.setOnConnect(this._onConnect);
	}

	_onConnect() {
		return this.module.api.authenticate('user.sub', 'authenticate')
			.then(roles => {
				// Set the roles model
				let o = Object.assign({}, this.model.roles.props);
				for (let k in o) {
					o[k] = undefined;
				}
				for (let r of roles) {
					o[r] = true;
				}
				this.model.roles.set(o);
				// Reset active role if we lost the currently active one
				if (this.model.activeRole && !o[this.model.activeRole]) {
					this.model.set({ activeRole: this._getMainRole() });
				}
			})
			.catch(() => this._onUnsubscribe());
	}

	_getMainRole() {
		let p = this.model.roles.props;
		for (let r of mainRoles) {
			if (p[r.id]) {
				return r.id;
			}
		}
		return null;
	}

	_getCurrentUser() {
		if (!this.userPromise) {
			this.userPromise = this.module.api.call('user.sub', 'getUser')
				.then(user => {
					// Get active role
					let activeRole = this.model.activeRole;
					// Load from sessionStorage if available
					if (!activeRole && sessionStorage) {
						activeRole = sessionStorage.getItem('auth.user.' + user.id + '.activeRole');
					}
					// Get first matching role
					if (!activeRole || !this.model.roles[activeRole]) {
						activeRole = this._getMainRole();
					}
					this.model.set({
						loggedIn: true,
						user,
						activeRole
					});
					user.on('unsubscribe', this._onUnsubscribe);
					this.extUser.resolve(user);
					return user;
				})
				.catch(err => {
					this.userPromise = null;
					this.extUser.reject(err);
					throw err;
				});
		}
		return this.userPromise;
	}

	_onUnsubscribe() {
		// Remove user model
		if (this.model.user) {
			this.model.user.off('unsubscribe', this._onUnsubscribe);
			this.model.set({
				loggedIn: false,
				user: null,
				activeRole: null
			});
			this._createExtUserPromise();
			// Clear roles
			let o = Object.assign({}, this.model.roles.props);
			for (let k in o) {
				o[k] = undefined;
			}
			this.model.roles.set(o);
		}
		this.userPromise = null;
	}

	_createExtUserPromise() {
		this.extUserPromise = new Promise((resolve, reject) => {
			this.extUser = { resolve, reject };
		});
	}

	dispose() {
		this._onUnsubscribe();
	}
}

export default Auth;
